import React from "react";
import "./Incio.css";
import lotus from "../../assets/imgs/lotus.svg";
import estudo from "../../assets/imgs/estudo.svg";
import equilibrio from "../../assets/imgs/equilibrio.svg";
import meditation from "../../assets/imgs/meditation.svg";
import marilia from "../../assets/imgs/fotoinicio.png";

function Incio() {
  return (
    <div className="incio-wrapper">
      <div className="incio-container">
        <div className="incio-content">
         

      
          <div className="inicio-flex">
            <div className="iconeep">
              <img className="iconyoga" src={estudo} alt="" />
              <h4>Meu Objetivo</h4>
              <p>Facilitar sua experiência no Yoga e ajudar a construir um lugar de repouso interno, sem culpa. O yoga é um caminho particular.</p>
            </div>
            <div className="iconeep">
            <img className="iconyoga" src={equilibrio} alt="" />
              <h4>Propósito</h4>
              <p>Despertar o interesse em autocrescimento pessoal. 
              </p>

            </div>
          </div>
          <div className="inicio-flex">
            <div className="iconeep">
            <img className="iconyoga" src={lotus} alt="" />
              <h4>Práticas simples</h4>
              <p>Planejamos juntos pequenas pausas diárias para produzir um descanso interno, com o objetivo de minimizar os danos da vida corrida, como o estresse, a depressão, a ansiedade, dores físicas e emocionais. 
              </p>

            </div>
            <div className="iconeep">
            <img className="iconyoga" src={meditation} alt="" />
              <h4>Benefícios</h4>
              <p>Todas as atividades buscam integrar a capacidade de restabelecer a clareza mental, obter um poder de compreensão sobre si mesmo e dedicar o tempo suficiente na percepção da observação das flutuações da mente e das emoções.</p>

            </div>
          </div>
        </div>

      </div>
    </div>
  );
}

export default Incio;
