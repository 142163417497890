import React, { useEffect } from 'react';
import './Yogamente.css';
import MainBtn from '../MainBtn/MainBtn';
import Formulario from '../formulario/Formulario';

function Yogamente() {
  useEffect(() => {
    // Função para rolar para o topo
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='yoga-wrapper'>
      <div className='yoga-container'>
        <div className='yoga-content'>
          <br />
          <br />
          <br />
          <h2>Yoga Mente</h2>
          <p>
          Vivencie afeto e compreensão do próprio corpo como um templo que abriga a essência e amplia a consciência para reflexões internas.
            <br /><br />
            <strong>Duração:</strong> 1h 30min<br />
            <strong>Dias:</strong> Terças e Quintas<br />
            <strong>Horários:</strong> das 7h 30min às 9h<br />
           
          </p>
          <h3>Tenho interesse em:</h3>
          <div className='tenho-interesse-form'>
            <Formulario />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Yogamente;
