import React from "react";
import "./Aulas.css";
import Especialidades from "../especialidades/Especialidades";
import servico1 from "../../assets/imgs/1.svg";
import servico2 from "../../assets/imgs/2.svg";
import servico3 from "../../assets/imgs/3.svg";
import servico4 from "../../assets/imgs/4.svg";
import servico5 from "../../assets/imgs/5.svg";
import servico6 from "../../assets/imgs/6.svg";
import servico7 from "../../assets/imgs/7.svg";
import servico8 from "../../assets/imgs/8.svg";

function Aulas() {
  return (
    <div className="aulas-wrapper">
      <div className="aulas-container">
        <div className="aulas-content">
          <h2>Aulas</h2>
          <br />
          <p>
            Encontre a sua!
          </p>
          <br />
          <br />
          <Especialidades />
          
        
          <br />
          <br />
          {/* <MainBtn link='https://wa.me/5584996130349' alt={true} children="Saiba mais sobre todos os serviços" /> */}
        </div>
      </div>
    </div>
  );
}

export default Aulas;
