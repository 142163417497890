import React, { useEffect } from 'react';
import './Sala.css';
import MainBtn from '../MainBtn/MainBtn';
import Formulario from '../formulario/Formulario';

function Sala() {
  useEffect(() => {
    // Função para rolar para o topo
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='sala-wrapper'>
      <div className='sala-container'>
        <div className='sala-content'>
          <br />
          <br />
          <br />
          <h2>Sala do descanso (presencial e on-line)
          </h2>
          <p>
          Trabalhar a construção da responsabilidade como uma habilidade de responder pelas próprias ações e às demandas da própria vida.
            <br /><br />
            <strong>Duração:</strong> 1h 30min<br />
            <strong>Dias:</strong> Terças e Quintas<br />
            <strong>Horários:</strong> das 7h 30min às 9h<br />
           
          </p>
          <h3>Tenho interesse em:</h3>
          <div className='tenho-interesse-form'>
            <Formulario />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sala;
