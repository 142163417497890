const Link = 'Link';
const Scroll = 'Scroll';

const menuItems = [
  {
    label: 'Início',
    type: Scroll,
    to: 'incio',
  },
  {
    label: 'Aulas',
    type: Scroll,
    to: 'aulas',
  },
  {
    label: 'Projetos',
    type: Scroll,
    to: 'modalidadesonline',
  },
  {
    label: 'Sobre mim',
    type: Scroll,
    to: 'sobreainstrutora',
  },
  {
    label: 'Depoimentos',
    type: Scroll,
    to: 'depoimentos',
  }
  
];

export default menuItems;

