import React from "react";
import "./SobreAIntrurora.css";
import MainBtn from "../MainBtn/MainBtn";
import marilia6 from "../../assets/imgs/marilia6.jpg";

function SobreAIntrurora() {
  return (
    <div className="sobreaintrurora-wrapper">
      <div className="sobreaintrurora-container">
        <div className="sobreaintrurora-content">
          <h2>Sobre mim</h2>
          
          <ul>
            <li>
              <h4>Minha jornada com Yoga</h4>
              <br />
              <p>
              Iniciei Yoga aos 46 anos com o objetivo de apenas conseguir a mobilidade física de sentar e levantar do chão sem dificuldades até os 100 anos rsrsrs. Foi através da prática regular que descobri que o yoga ia além do que executar posturas físicas. Trata-se de vivenciar um estado alterado de consciência diariamente com base na observação do momento presente. Assim percorri novos caminhos: Reiki, Yoga Massagem Ayurvédica(Kusum Modak), Aromaterapia Vibracional, Hatha Yoga, Alimentação Natural, Método de Yoga Restaurativo e Terapia Restaurativa por Miila Dezertt e Maha Lilah - O Jogo da Vida.
              </p>
            </li>
            <li>
              <h4>Como a Yoga mudou minha vida</h4>
              <br />
              <p>
              Como mulher, mãe de 3 e filha, buscava reconstruir um espaço interno no silêncio  e nutrir meu ‘SER’. Essas mudanças foram tão profundas que reverberam até hoje em minha jornada na vida, no entanto, só ocorreu a lucidez mediante o apontamento alheio. Internalizar o conhecimento adquirido é doloroso e cansativo, porém, não tem mais volta, uma vez em busca não conseguimos retornar ao início, no entanto, finalizar ciclos e iniciar novas caminhadas com amorosidade, respeito e integrando o ser caminhante.

              </p>
            </li>
            <li>
              <h4>A experiência no Yoga Restaurativo</h4>
              <br />
              <p>
              Constatei uma exaustão enraizada em meu ‘SER’ dos acúmulos de experiências negativas de vários ciclos de existência, me permite investigar e transformar alguns hábitos e quanto mais aprendo, mais descubro que nada sei, não consigo parar. Tornou-se meu refúgio pessoal diário e constante, onde encontro paz e renovo o vigor pela vida e ainda me construo junto em grupos de rodas de conversas.

              </p>
            </li>
            <li>
              <h4>Projeto Voluntário no Hospital Psiquiátrico Severino Lopes</h4>
              <br />
              <p>
              Este visa levar de forma lúdica e simples aos pacientes bons momentos compartilhados e despertar em roda de conversas ferramentas para autocuidado na busca por uma melhor qualidade de vida.

              </p>
            </li>
            
          </ul>
        </div>
        <div className="sobreaintrurora-img">
          <img src={marilia6} alt="" />
        </div>
      </div>

      <div className="flexfrase">
        <br /><br />
        <h1 className="frasefinal">Construa bem estar em uma jornada para o autoconhecimento!</h1>
      </div>
    </div>
  );
}

export default SobreAIntrurora;
