import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Especialidades.css";
import Yogamente from "../../components/yogamente/Yogamente";
import Sala from "../../components/saladescanso/Sala";

function Especialidades() {
  const navigate = useNavigate();
  const [aulaSelecionada, setAulaSelecionada] = useState(null);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handlePageChange = (aula) => {
    scrollToTop();
    setTimeout(() => {
      if (aula === 1) {
        navigate("/yogamente");
      } else if (aula === 2) {
        navigate("/saladescanso");
      }
    }, 500); // Atraso para garantir que o scroll ocorra antes da navegação
  };

  return (
    <div className="especialidades-wrapper">
      
      <div className="especialidades-container">
        {/* Aula YogaMente */}
        <div className="aula-item">
          <h2>YogaMente</h2>
          <p>
            É a junção de posturas leves a moderadas de Hatha Yoga com as
            colunas do Método de Yoga Restaurativo: tempo, toque, afeto e
            diálogo. Minha proposta é trabalhar as colunas: tempo e diálogo em
            rodas de conversas diante das experiências vivenciadas em prática.
          </p>
          <button onClick={() => handlePageChange(1)}>Saiba Mais</button>
        </div>

        {/* Aula Sala de Descanso */}
        <div className="aula-item">
          <h2>Sala do descanso (presencial e on-line)</h2>
          <p>
            Uma prática para mergulhar na autocompaixão e desenvolver esta
            habilidade em si mesma. É reservar o sacrifício de pausar pela
            experiência de sentir o momento presente em ‘pausa’. Trabalhar a
            construção da responsabilidade como uma habilidade de responder às
            demandas da vida.
          </p>
          <button onClick={() => handlePageChange(2)}>Saiba Mais</button>
        </div>
      </div>

      {/* Renderização condicional, se necessário */}
      <div className="servicos-grid">
        {aulaSelecionada === 1 && <Yogamente />}
        {aulaSelecionada === 2 && <Sala />}
      </div>
    </div>
  );
}

export default Especialidades;
