import React from 'react';
import './CTA1.css';
import MainBtn from '../MainBtn/MainBtn';
import marilia from "../../assets/imgs/mariliainicio.png";
function CTA1() {
  return (
    <div className='cta1-wrapper'>
      <div className='cta1-container'>
        <div className='cta1-content'>

          <div className="inicio">
            <br /><br />
            <h1 className='h1fofo'>Terapeuta integrativa</h1>
          </div>
          
          
        </div>
        
        </div>
      </div>
  
  );
}

export default CTA1;
