import React from "react";
import "./Footer.css";
import logo2 from "../../assets/imgs/teste.png";


const phoneNumber = "5584996060201";
  const message = encodeURIComponent(
    "Gostei do desenvolvimento do seu site, gostaria de saber como funciona?"
  );
  const whatsappUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${message}`;
const Footer = ({ handleNavigationBtn, menuItems, socialLinks }) => {
  return (

  

    <footer className="footer-background">
      <div className="footer-container">
        <div className="footer-logo">
          <img className="logo-footer" src={logo2} alt="" />
          "Yoga é a expressão de uma experiência que se vivencia e prepara o caminho para transformações com escolhas conscientes. O Yoga é um caminho particular."
          
        </div>

        <div className="footer-item">
          <h5>NAVEGAÇÃO</h5>
          <div>
            {menuItems.map((item, index) => (
              <li key={index}>
                <button onClick={() => handleNavigationBtn(item)}>
                  {item.label}
                </button>
              </li>
            ))}
          </div>
        </div>

        <div className="footer-item">
          <h5>ÁREAS E ESPECIALIDADES</h5>
          <div className="especialidades">
            <p>Yoga Integrativa e Terapias</p>
            <p>Aulas Diversificadas</p>
            <p>Projeto Serena Idade</p>
            <p>Reiki Online e Maha Lilah</p>
            <p>Produtos Exclusivos</p>
          </div>
        </div>

        <div className="footer-item">
          <div>
            <h5>Contato</h5>
            <p>Email: marilia.mariano@gmail.com</p>
            <p>Telefone: (84) 99613-0349</p>
          </div>
        </div>
      </div>

      <div className="footer-copy">
        <p>&copy; 2024 Marília Mariano. Todos os direitos reservados.</p>
        <a className="luanadev" href={whatsappUrl} target="_blank" rel="noreferrer">
          Desenvolvido por Luana Salmito
        </a>
        <div className="footer-socials">
          {socialLinks.map((link, index) => (
            <a key={index} href={link.url} target="_blank" rel="noreferrer">
              <i className={`${link.icon}`} aria-label={link.label}></i>
            </a>
          ))}
        </div>
      </div>
    </footer>
  );
};

export default Footer;


